import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { globalHistory } from '@reach/router'
import { format } from 'date-fns'

const SchemaWebPage = ({ title, desc, banner, pathname }) => {

  const { site } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      siteLanguage,
      author,
    },
  } = site

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${globalHistory.location.pathname}`,
  }

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    url: `${siteUrl}${globalHistory.location.pathname}`,
    datePublished: buildTime,
    dateModified: Date(),
    name: defaultTitle,
    description: defaultDescription,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: `${format(new Date(), 'yyyy')}`,
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}/img/og-image-square.png`,
      },
    },
  }


  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      </Helmet>
    </>
  )
}

export default SchemaWebPage

SchemaWebPage.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
}

SchemaWebPage.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
}

const query = graphql`
  query SchemaWebPage {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        siteLanguage
        author
      }
    }
  }
`
