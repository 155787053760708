import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"
import tachyons from 'tachyons-components'
import { Match } from '@reach/router'
import Flexbar from '../components/Flexbar.js'
import Footnav from '../components/Footnav.js'
import Footer from '../components/Footer.js'
import SEO from '../components/Seo.js'
import '../styles/main.scss'

const StyledBody = tachyons("div")`
${props => (props.home ? "ph0" : "ph3 ph4-ns")}
`

const StyledBodyWithMatch = ({ children }) => (
  <Match path="/projects/*">
    {props => (
      props.match
      ? <StyledBody home="true">{children}</StyledBody>
      : <StyledBody>{children}</StyledBody>
      )
    }
  </Match>
)

const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: "active" }
    : null
}

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <div
        style={{
          backgroundColor: "var(--bg)",
          color: "var(--textPrimary)",
          transition: "color 0.2s ease-out, background 0.2s ease-out"
        }}
      >
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/img/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/favicon-16x16.png"
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href="/img/safari-pinned-tab.svg"
            color="#0055ff"
          />
        </Helmet>
        <SEO />
        <section id="top"></section>
        <Flexbar />
        <StyledBodyWithMatch getProps={isPartiallyActive}>
          {children}
        </StyledBodyWithMatch>
        <Footnav />
        <Footer />
      </div>
    )}
  />
);

export default TemplateWrapper
