import React from 'react'
import tachyons from 'tachyons-components'
import { Match } from '@reach/router'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { format } from "date-fns"

const Copyright = tachyons("footer")`
pb5 pb3-ns mb2 mid-gray w-100 pt4 pt3-l ph3 ph4-ns footer hr-top
`
const CopyrightText = tachyons("div")`
db mb2 tc tl-l mt4 mt0-l lh-copy lh-none-l
`
const PrivacySettings = tachyons("button")`
db flex-l center fl-l br2 ph3 pv2 bg-secondary br-secondary button pointer 
`
const PrivacyContainer = tachyons("div")`
w-100 w-75-l f6 
`
const FooterContainer = tachyons('div')`
wrap-nav flex flex-column flex-row-reverse-l items-end
`
const SocialContainer = tachyons("ul")`
flex w-100 w-25-l self-end justify-around justify-around-m justify-between-l pl0 
`
const SVGIconList = tachyons("li")`
inline-flex
`
const SVGIcon = tachyons("a")`
link light-gray svg-icon
`

const FooterWithMatch = ({ children }) => (
  <Match path="/">
    {props => (
      props.match
      ? <Copyright home="true">{children}</Copyright>
      : <Copyright>{children}</Copyright>
      )
    }
  </Match>
)

const Footer = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookieBar: false,
    };

     if (Cookies.get("gatsby-gdpr-google-analytics")) {
       this.state.showCookieBar = false;
     }
  }

  render() {
    return (
      <FooterWithMatch>
        <FooterContainer>
          <SocialContainer>
            <SVGIconList>
              <SVGIcon
                href="https://linkedin.com/in/ajmalafif"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
              >
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="linkedin"
                  role="img"
                  viewBox="0 0 16 16"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="1.414"
                >
                  <path
                    d="M13.632 13.635h-2.37V9.922c0-.886-.018-2.025-1.234-2.025-1.235 0-1.424.964-1.424 1.96v3.778h-2.37V6H8.51V7.04h.03c.318-.6 1.092-1.233 2.247-1.233 2.4 0 2.845 1.58 2.845 3.637v4.188zM3.558 4.955c-.762 0-1.376-.617-1.376-1.377 0-.758.614-1.375 1.376-1.375.76 0 1.376.617 1.376 1.375 0 .76-.617 1.377-1.376 1.377zm1.188 8.68H2.37V6h2.376v7.635zM14.816 0H1.18C.528 0 0 .516 0 1.153v13.694C0 15.484.528 16 1.18 16h13.635c.652 0 1.185-.516 1.185-1.153V1.153C16 .516 15.467 0 14.815 0z"
                    fillRule="nonzero"
                  />
                </svg>
              </SVGIcon>
            </SVGIconList>
            <SVGIconList>
              <SVGIcon
                href="https://github.com/ajmalafif"
                target="_blank"
                rel="noopener noreferrer"
                title="GitHub"
              >
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="github"
                  role="img"
                  viewBox="0 0 16 16"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="1.414"
                >
                  <path d="M8 0C3.58 0 0 3.582 0 8c0 3.535 2.292 6.533 5.47 7.59.4.075.547-.172.547-.385 0-.19-.007-.693-.01-1.36-2.226.483-2.695-1.073-2.695-1.073-.364-.924-.89-1.17-.89-1.17-.725-.496.056-.486.056-.486.803.056 1.225.824 1.225.824.714 1.223 1.873.87 2.33.665.072-.517.278-.87.507-1.07-1.777-.2-3.644-.888-3.644-3.953 0-.873.31-1.587.823-2.147-.083-.202-.358-1.015.077-2.117 0 0 .672-.215 2.2.82.638-.178 1.323-.266 2.003-.27.68.004 1.364.092 2.003.27 1.527-1.035 2.198-.82 2.198-.82.437 1.102.163 1.915.08 2.117.513.56.823 1.274.823 2.147 0 3.073-1.87 3.75-3.653 3.947.287.246.543.735.543 1.48 0 1.07-.01 1.933-.01 2.195 0 .215.144.463.55.385C13.71 14.53 16 11.534 16 8c0-4.418-3.582-8-8-8" />
                </svg>
              </SVGIcon>
            </SVGIconList>
            <SVGIconList>
              <SVGIcon
                href="https://dribbble.com/ajmalafif"
                target="_blank"
                rel="noopener noreferrer"
                title="Dribbble"
              >
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="1.414"
                >
                  <path d="M23.76 9.58A11.99 11.99 0 0 0 0 12a12.08 12.08 0 0 0 3.51 8.49 12.12 12.12 0 0 0 6.07 3.27A11.99 11.99 0 0 0 24 12a12 12 0 0 0-.24-2.42zm-1.51 2.32c-.15-.03-3.62-.78-7.14-.34a38.64 38.64 0 0 0-.9-2.01c4.04-1.66 5.69-4.03 5.7-4.06a10.2 10.2 0 0 1 2.34 6.4zm-3.48-7.6c-.03.05-1.49 2.27-5.35 3.72a52.06 52.06 0 0 0-3.83-5.98 10.23 10.23 0 0 1 9.18 2.27zM7.63 2.74a61.6 61.6 0 0 1 3.8 5.9A37.91 37.91 0 0 1 1.97 9.9c.67-3.18 2.8-5.8 5.66-7.16zM1.75 12l.01-.32c.18 0 5.25.11 10.52-1.46.3.57.58 1.15.83 1.74l-.4.12c-5.53 1.79-8.34 6.76-8.34 6.76A10.21 10.21 0 0 1 1.76 12zM12 22.25a10.2 10.2 0 0 1-6.53-2.35l.23.18s1.97-4.29 8.04-6.4l.07-.02a42.64 42.64 0 0 1 2.2 7.78 10.2 10.2 0 0 1-4.01.8zm5.73-1.75c-.1-.62-.65-3.63-2-7.32 3.31-.53 6.18.38 6.39.45a10.26 10.26 0 0 1-4.4 6.87z" />
                </svg>
              </SVGIcon>
            </SVGIconList>
            <SVGIconList>
              <SVGIcon
                href="https://twitter.com/ajmalafif"
                target="_blank"
                rel="noopener noreferrer"
                title="Twitter"
              >
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="twitter"
                  role="img"
                  viewBox="0 0 16 16"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="1.414"
                >
                  <path
                    d="M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.375-1.337.648-2.085.795-.598-.638-1.45-1.036-2.396-1.036-1.812 0-3.282 1.468-3.282 3.28 0 .258.03.51.085.75C5.152 5.39 2.733 4.084 1.114 2.1.83 2.583.67 3.147.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.416-.02-.617-.058.418 1.304 1.63 2.253 3.067 2.28-1.124.88-2.54 1.404-4.077 1.404-.265 0-.526-.015-.783-.045 1.453.93 3.178 1.474 5.032 1.474 6.038 0 9.34-5 9.34-9.338 0-.143-.004-.284-.01-.425.64-.463 1.198-1.04 1.638-1.7z"
                    fillRule="nonzero"
                  />
                </svg>
              </SVGIcon>
            </SVGIconList>
            <SVGIconList>
              <SVGIcon
                href="/rss.xml"
                target="_blank"
                rel="noopener noreferrer"
                title="RSS Feed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="bevel"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  strokeMiterlimit="1.414"
                >
                  <path d="M4 11a9 9 0 0 1 9 9"></path>
                  <path d="M4 4a16 16 0 0 1 16 16"></path>
                  <circle cx="5" cy="19" r="1"></circle>
                </svg>
              </SVGIcon>
            </SVGIconList>
          </SocialContainer>
          <PrivacyContainer>
            <CopyrightText>
              Copyright &copy; 2009–{format(new Date(), "yyyy")} Ajmal Afif, All
              Rights Reserved
            </CopyrightText>
            <PrivacySettings
              onClick={() => {
                Cookies.remove("gatsby-gdpr-google-analytics");
                this.setState({ showCookieBar: true });
              }}
            >
              {" "}
              Cookie Settings
            </PrivacySettings>
          </PrivacyContainer>
        </FooterContainer>
        {this.state.showCookieBar && (
          <CookieConsent
            location="bottom"
            cookieName="gatsby-gdpr-google-analytics"
            sameSite="strict"
            flipButtons={true}
            disableStyles={true}
            disableButtonStyles={true}
            buttonText="Enable"
            buttonClasses="allow-button dib w-50 w-auto-l br2 ph3 pv2 bg-secondary br-secondary pointer"
            buttonWrapperClasses="w-100 w-25-l flex self-end-l"
            declineButtonClasses="decline-button dib w-50 w-auto-l br2 ph3 pv2 bg-secondary br-secondary pointer ml0 ml2-l"
            enableDeclineButton={true}
            declineButtonText="Disable"
            containerClasses="cookie-consent wrap-nav f6 lh-copy br2 ph3 pv2 bg-secondary bs-secondary br-secondary flex justify-between"
            contentClasses="cookie-consent-content mb2 mb0-ns mb0-l w-100 w-75-l"
            expires={365}
            onAccept={() => {
              this.setState({ showCookieBar: false });
            }}
            onDecline={() => {
              this.setState({ showCookieBar: false });
            }}
          >
            This site uses cookies to enhance overall user experience. Feel free to enable or disable cookies at any time.
          </CookieConsent>
        )}
      </FooterWithMatch>
    );
  }
};

export default Footer
