import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Match } from '@reach/router'
import tachyons from 'tachyons-components'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const TogglerSlider = styled("div")`
  background-color: #e8e9ea;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 40px;

  &:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 12px;
    left: 4px;
    position: absolute;
    width: 12px;
    border-radius: 50%;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.38);
  }
`
const StyledToggler = styled("label")`
  height: 20px;
  position: relative;
  width: 32px;
  margin-bottom: -4px;
  margin-left: 1rem;
  @media (min-width: 960px) {
    top: -2px;
  }
  @media (max-width: 959px) {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const TogglerInput = styled("input")`
  margin-top: 3px;
  &:not(:checked) {
    margin-left: 2px;
    @media (max-width: 959px) {
      margin-left: 4px;
    }
  }
  &:checked {
    margin-left: 16px;
    @media (max-width: 959px) {
      margin-left: 14px;
    }
  }
  &:checked + ${TogglerSlider} {
    background-color: #424242;
  }
  &:checked + ${TogglerSlider}:before {
    transform: translateX(12px);
    box-shadow: -1px 2px 4px 0 rgba(0,0,0,0.38);
  }

  + ${TogglerSlider}:after {
    position: relative;
    display: inline-block;
    text-align: left;
    width: 52px;
    font-size: 14px;
    line-height: 1.8;
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    color: var(--textDarkMode);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: var(--fontSemiBold);
    letter-spacing: 1.5px;
    @media (min-width: 959px) {
      left: 40px;
    }
    @media (max-width: 959px) {
      position: absolute;
      right: 38px;
      text-align: right;
      line-height: 20px;
    }
  }

  &:checked + ${TogglerSlider}:after {
    content: "Dark"
  }
  &:not(:checked) + ${TogglerSlider}:after {
    content: "Light"
  }
`
const StyledLink = styled(Link)`
  font-weight: var(--fontSemiBold);
  color: var(--textTitle);
  text-decoration: none;

  &:hover {
    color: var(--textLink);
  }
`
const StyledLinkLogo = styled(StyledLink)`
  font-weight: var(--fontBold);
  color: var(--textTitle);
  text-decoration: none;
  letter-spacing: -0.1px;
  @media screen and (max-width: 959px) {
    font-size: 1.1rem !important;
  }
`
const HeaderLogo = tachyons("h1")`
mt3 mb3 f5 w-100 w-100-m w-two-thirds-l tl fw6 flex justify-between justify-start-l flex-none-l
`
const UnorderedList = tachyons("ul")`
mt3 mb3 w-100 w-100-m w-third-l self-end flex justify-between justify-around-m justify-between-l
`
const StyledUnorderedList = styled(UnorderedList)`
font-family: 'Overpass Mono', monospace;
`
const List = tachyons("li")`
f5 dib
`
const StyledList = styled(List)`
  @media screen and (max-width: 959px) {
    font-size: 1.1rem !important;
  }
  @media screen and (max-width: 413px) { 
    font-size: 1rem !important;
  }
  @media screen and (max-width: 359px) { 
    font-size: 15px !important;
  }
`
const Header = tachyons("header")`
w-100 ph3 ph4-ns left-0 top-0 right-0 z-2 o-90 fixed-ns 
`
const Nav = tachyons("nav")`
flex flex-wrap items-center pt2 pb1 pv1-ns wrap-nav
`

const HeaderWithMatch = ({ children }) => (
  <Match path="/">
    {props => (
      props.match
        ? <Header home="true">{children}</Header>
        : <Header>{children}</Header>
    )
    }
  </Match>
)

const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: "active" }
    : null
}

const Flexbar = class extends React.Component {

  render() {
    return (
      <HeaderWithMatch>
        <Nav role="navigation" aria-label="main-navigation">
          <HeaderLogo>
            <StyledLinkLogo id="top" to="/" title="Ajmal Afif">
              Ajmal Afif
            </StyledLinkLogo>
            <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <StyledToggler for="theme-mode" id="themeModeLabel">
                  <TogglerInput
                    type="checkbox"
                    name="theme-mode"
                    id="theme-mode"
                    aria-labelledby="themeModeLabel"
                    onChange={e =>
                      toggleTheme(e.target.checked ? "dark" : "light")
                    }
                    checked={theme === "dark"}
                  />
                  <TogglerSlider />
                </StyledToggler>
              )}
            </ThemeToggler>
          </HeaderLogo>
          <StyledUnorderedList>
            <StyledList>
              <StyledLink
                to="/projects/"
                getProps={isPartiallyActive}
                activeStyle={{
                  color: "var(--textLink)",
                  textDecoration: "none"
                }}
              >
                Projects
              </StyledLink>
            </StyledList>
            <StyledList>
              <StyledLink
                to="/experience/"
                activeStyle={{
                  color: "var(--textLink)"
                }}
              >
                Experience
              </StyledLink>
            </StyledList>
            <StyledList>
              <StyledLink
                to="/about/"
                getProps={isPartiallyActive}
                activeStyle={{
                  color: "var(--textLink)",
                  textDecoration: "none"
                }}
              >
                About
              </StyledLink>
            </StyledList>
            <StyledList>
              <StyledLink
                to="/blog/"
                getProps={isPartiallyActive}
                activeStyle={{
                  color: "var(--textLink)",
                  textDecoration: "none"
                }}
              >
                Blog
              </StyledLink>
            </StyledList>
          </StyledUnorderedList>
        </Nav>
      </HeaderWithMatch>
    );
  }
}

export default Flexbar
