import React from 'react'
import styled from 'styled-components'
import { Match } from '@reach/router'
import tachyons from 'tachyons-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const BacktoTopContainer = tachyons('div')`
tc mb4
`
const StyledAnchorLink = styled(AnchorLink)`
  color: var(--textTitle);
  transition: color .15s ease-in;
  text-decoration: none;

  &:hover {
    color: var(--textLink);
  }
`
const Header = tachyons("footer")`
ph4 w-100 mt6
`
const Nav = tachyons("nav")`
w-100 tc
`

const FooterWithMatch = ({ children }) => (
  <Match path="/">
    {props => (
      props.match
      ? <div home="true"></div>
      : <Header>{children}</Header>
      )
    }
  </Match>
)

const Footnav = class extends React.Component {

render() {
  return (
    <FooterWithMatch>
    <Nav role="navigation" aria-label="main-navigation">
      <BacktoTopContainer>
        <StyledAnchorLink href="#top">Back to top ↑</StyledAnchorLink>
      </BacktoTopContainer>
    </Nav>
  </FooterWithMatch>
  )}
}

export default Footnav
