
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: "smooth";
  }
  
  body {
    font-size: 18px;
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    overflow-wrap: break-word;
    --bg: #fff;
    --bgAccent: #F3F3F6;
    --textPrimary: #475159;
    --textSecondary: rgba(0,0,0, .55);
    --textTitle: #333;
    --textAccent: rgb(238, 238, 238);
    --textDarkMode: #9E9E9E;
    --textLink: #0055ff;
    --textLinkFocus: rgba(0, 85, 255, 0.60);
    --textLinkHover: rgba(0, 85, 255, 0.03);
    --textLinkSecondary: #0044cc;
    --linkBgSecondary: rgba(0, 85, 255, 0.09);
    --linkHeader: #ccc;
    --hr: rgb(238, 238, 238);
    --buttonBoxShadow: rgba(14,30,37,0.12);
    --buttonPrimary: #0044cc;
    --buttonSecondary: #fefefe;
    --buttonBorder: #e9ebeb;
    --fontNormal: 400;
    --fontSemiBold: 600;
    --fontBold: 800;
    --pageProgress: rgba(0, 85, 255, 0.70);
    background-color: var(--bg);
  }

  body.dark {
    --bg: #121212;
    --bgAccent: #222;
    --textPrimary: rgba(255, 255, 255, 0.87);
    --textSecondary: rgba(255,255,255, .55);
    --textTitle: rgba(255, 255, 255, 0.70);
    --textAccent: rgba(255, 255, 255, 0.19);
    --textDarkMode: #9E9E9E;
    --textLink: #4ca3ff;
    --textLinkFocus: rgba(0, 85, 255, 0.87);
    --textLinkHover: rgba(76, 163, 255, 0.07);
    --textLinkSecondary: rgba(0, 85, 255, 0.08);
    --linkBgSecondary: #0044cc;
    --linkHeader: #444;
    --hr: #212121;
    --buttonBoxShadow: rgba(255, 255, 255, 0.08);
    --buttonPrimary: #0044cc;
    --buttonSecondary: rgba(0, 85, 255, 0.08);
    --buttonBorder: rgba(48, 48, 48, 0.19);
    --pageProgress: rgb(76, 163, 255);
  }

  a{
    color: var(--textLink);
  }

  header {
    background-color: var(--bg);
    border-bottom: 1px solid var(--hr);
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Overpass Mono', monospace;
    letter-spacing: -1.1px;
    color: 'var(--textTitle)';
  }

  h1 {
    font-size: 36px;
    font-weight: normal;
  }

  h2,
  h3 {
    font-weight: var(--fontSemiBold);
    margin-top: 2rem;
  }

  p {
    margin-bottom: 1rem;
    color: 'var(--textPrimary)' !important;
  }

  figcaption {
    max-width: 1080px;
    text-align: center;
    padding: 0.5em 1em;
    margin: 2rem auto -3rem;
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
    font-size: 0.9rem;
    line-height: 2;
    color: var(--textTitle);
  }

  .gatsby-resp-image-figure figcaption {
    margin-top: 0 !important;
  }

  .lh-copy {
    color: var(--textPrimary);
  }

  h2 + p,
  h3 + p,
  p:first-of-type {
    margin-top: 0;
  }

  nav ul {
    padding-left: 0;
  }

  ul {
    padding-left: 1.25rem;
  }

  .hr-top {
    border-top: 1px solid var(--hr);
  }

  .hr-bottom {
    border-bottom: 1px solid var(--hr);
  }

  .link-header {
    color: var(--textPrimary);
  }

  .link:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }

  footer {
    button {
      font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
        color: var(--textPrimary);
      &:hover,
      &:focus {
        color: var(--textLink);
        background-color: var(--buttonSecondary);
        background-image: linear-gradient(135deg,hsla(0,0%,100%,0.09),hsla(0,0%,100%,0.05));
      }
    }
  }

  .button {
    font-family: 'Overpass Mono', monospace;
    letter-spacing: -0.5px;
  }

  .button-primary {
    color: rgba(255, 255, 255, 0.87);
  }

  h1 .autolink-headers svg,
  h2 .autolink-headers svg,
  h3 .autolink-headers svg,
  h4 .autolink-headers svg,
  h5 .autolink-headers svg,
  h6 .autolink-headers svg {
    visibility: visible;
  }

  .bg-hover:hover {
    background-color: var(--textLinkHover);
  }

  .bg-primary {
    font-weight: var(--fontSemiBold);
    background-color: var(--buttonPrimary);
    box-shadow: 0 2px 4px 0 var(--textLinkFocus);
    background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.30), hsla(0, 0%, 100%, 0.05));
  }

  .bg-secondary {
    background-color: var(--buttonSecondary);
  }

  .br-secondary {
    border: 1px solid var(--buttonBorder);
    background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.09), hsla(0, 0%, 100%, 0.05));
  }

  .bs-secondary {
    box-shadow: 0 2px 4px 0 var(--buttonBoxShadow);
  }

  .wrap {
    position: relative;
    margin: 0 auto;
    max-width: 700px;
  }

  .wrap-nav {
    position: relative;
    margin: 0 auto;
    max-width: 1080px;
  }

  .wrap-post {
    position: relative;
    margin: 0 auto;
    max-width: 34em;
  }

  .logo {
    font-size: 1rem;
  }

  :focus:not(:focus-visible) { 
    outline: none;
  }

  a.active {
    font-weight: var(--fontSemiBold);
    color: var(--textLink);
    text-decoration: none;
  }

  a.bg-primary:focus,
  a.bg-primary:hover,
  button:focus,
  button:hover {
    background-color: var(--buttonPrimary);
    background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0.3));
  }

  a.bg-secondary {
    color: var(--textTitle);
    font-weight: var(--fontSemiBold);
  }

  a.bg-secondary:focus,
  a.bg-secondary:hover {
    color: var(--textLink);
  }

  .about a.link:hover,
  .blog a.link:hover,
  .blog a:hover,
  .project a:hover,
  .post a:hover,
  nav a:hover {
    color: var(--textLink);
  }

  .about .link-external,
  .about .link-external:visited,
  .blog .link,
  .blog .link:visited {
    color: var(--textLink);
  }

  code[class*="language-text"] {
    padding: 0.2em 0.4em !important;
    border-radius: 3px !important;
    color: var(--textTitle) !important;
    background-color: var(--hr) !important;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 90%;
    font-feature-settings: "clig" 0, "calt" 0;
    font-variant: no-common-ligatures no-discretionary-ligatures no-historical-ligatures no-contextual;
  }

  .gatsby-highlight {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  code[class*="language-"], 
  pre[class*="language-"] {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }
  
  :not(pre)>code[class*=language-], 
  pre[class*=language-] {
    max-width: 1080px;
    margin: 5rem auto 3rem;
    border-radius: 0;
  }

  .gatsby-highlight pre[class*=language-]:before {
    font-size: 16px;
    font-family: Inter;
    content: '⌨️';
    position: absolute;
    color: var(--textPrimary);
    padding-top: 6px;
    text-align: center;
    top: -36px;
    width: 100%;
    height: 30px;
    background-color: #fafafa;
    box-shadow: inset 0 0 0 1px var(--buttonBoxShadow);
    background-image: linear-gradient(90deg,hsla(0, 3%, 70%, 0.15), hsla(0, 0%, 80%, 0.03));
    border-radius: 5px 5px 0 0;
    max-width: 1080px;
    margin-left: -18px;
  }

  .highlight,
  .measure-wide .highlight {
    background-color: var(--buttonSecondary);
    box-shadow: inset 0 0 0 1px var(--buttonBoxShadow);
    background-image: linear-gradient(90deg,hsla(0, 3%, 70%, 0.15), hsla(0, 0%, 80%, 0.03));
    padding: 1rem;
    border-radius: 2px;
  }
  
  .avatar {
    border-radius: 4px;
  }

  .post h4 {
    margin-bottom: 0.5em;
  }
  
  .single h2,
  .single h3 {
    line-height: 1.25;
  }

  .footer {
    background: var(--bg);
  }

  footer .link,
  footer .link:visited {
    color: #555 !important;
    background-color: transparent !important;
    line-height: 0;
  }

  footer a.link:hover,
  footer a:hover {
    color: var(--textLink) !important;
  }

  svg {
    width: 20px;
  }

  .svg-icon {
    width: 20px;
    height: 20px;
  }

  .blog .link-primary,
  .blog .link-primary:visited {
    color: var(--textLink);
    background-color: rgba(0, 85, 255, 0.03);
    border-bottom: 1px solid rgba(0, 85, 255, 0.1);
  }

  .disabled {
    cursor: not-allowed;
  }

  .image-frame {
    background-color: var(--bgAccent);
    border-radius: 4px;
  }

  .image-offset {
    margin-bottom: -5px;
  }

  .hero-bg {
    background-color: var(--bgAccent);
    margin-top: 26px;
    @media screen and (max-width: 479px) {
      margin-top: 0;
    }
  }

  .cookie-consent {
    font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    color: var(--textPrimary);
    background-color: var(--bg);
    left: 0;
    right: 0;
    margin-bottom: 8px;
    position: fixed;
    z-index: 999;
    @media screen and (min-width: 959px) and (max-width: 1080px) {
      margin-left: 8px;
      margin-right: 8px;
    }
    @media screen and (max-width: 959px) {
      margin-bottom: 0;
      padding-bottom: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      
      .allow-button {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .decline-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    @media screen and (min-width: 959px) {
    .allow-button {
      margin-left: auto;
      }
    }
    button {
      font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
        color: var(--textPrimary);
      &:hover,
      &:focus {
        color: var(--textLink);
        background-color: var(--buttonSecondary);
        background-image: linear-gradient(135deg,hsla(0,0%,100%,0.09),hsla(0,0%,100%,0.05));
      }
    }
  }

  @media screen and (min-width: 30em) {
    nav li:last-child {
      margin-right: 0;
    }
    .btn-paginate + .btn-paginate {
      margin-left: 0.75rem;
    }
    .avatar {
      max-height: 318px;
      max-width: 318px;
    }
  }

  @media screen and (max-width: 30em) {
    .logo {
      font-size: 1.125rem;
    }
  }

  @media screen and (max-width: 700px) {
    .button {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 60em) and (min-width: 30em) {
    .justify-around-m {
      justify-content: space-evenly;
    }
  }
`

export default GlobalStyle